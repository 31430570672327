/* =Teaser Section Layout
----------------------------------------------------------*/

.wcm-pgraph-teaser-section {
  @include make-row();
  @include breakpoint($md) {
    display: flex;
    margin-bottom: 30px;
  }
  .wcm-pgraph-text-teaser-wrap {
    // Make all 12 columns at mobile
    @include make-xs-column(12);
    display: flex;
    flex-direction: column;

    &:nth-last-child(2):first-child,
    &:nth-last-child(2):first-child ~ .wcm-pgraph-text-teaser-wrap {
      @include make-md-column(6);
    }

    &:nth-last-child(3):first-child,
    &:nth-last-child(3):first-child ~ .wcm-pgraph-text-teaser-wrap {
      @include make-md-column(4);
    }

    &:nth-last-child(4):first-child,
    &:nth-last-child(4):first-child ~ .wcm-pgraph-text-teaser-wrap {
      @include make-md-column(3);
    }
  }
  .wcm-pgraph-text-teaser {
    @include breakpoint($md) {
      margin-bottom: 0;
      height: 100%;
    }
  }
}
