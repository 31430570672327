.wcm-pgraph-img-teaser {
  $this: &;

  &__image {
    overflow: hidden;

    img {
      width: 100%;
      transition: all 0.3s ease 0s;
    }
  }

  &__text {
    background-color: $wcm-white;
    position: relative;
    padding: 2rem 0;

    @include breakpoint($md) {
      width: 84%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: -4rem;
      padding: 2rem 3rem;
      transform: translateY(-50%);
    }

    &__headline {
      color: $wcm-red;
      font-family: $wcm-bold;
      font-size: 30px;

      a {
        color: $wcm-red;
        text-decoration: underline;
        border: none;

        @include breakpoint($md) {
          text-decoration: none;
        }

        &.external-link {
          &:after {
            content: '';
            display: none;
          }
        }
      }
    }

    &__description {
      color: $wcm-dark-gray;
    }

    &__indicator {
      border-bottom: none;

      @include breakpoint($md) {
        display: block;
        position: absolute;
        height: 50%;
        top: 25%;
        right: 5%;
        border-left: 1px solid #ddd;
        padding-left: 30px;
        z-index: 10;

        &:after {
          content: "";
          position: relative;
          top: 33%;
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 0 10px 10px;
          border-color: transparent transparent transparent $wcm-bright-orange;
        }

        &.external-link {
          &:after {
            border: none;
            font-size: 20px;
            width: 10px;
            padding-left: 0px !important;
          }
        }
      }

      &.external-link {
        &:after {
          @include breakpoint((max: 991px)) {
            display: none !important;
          }
        }
      }
    }
  }

  &--linked {
    #{$this}__text__description {
      @include breakpoint($md) {
        padding-right: 90px;
      }
    }
  }

  &--over {
    #{$this}__image a img {
      @include breakpoint($md) {
        transform: scale(1.1);
      }
    }

    #{$this}__text__headline a {
      border: none;

      @include breakpoint($md) {
        text-decoration: underline;
      }
    }

    #{$this}__text__indicator {
      border-bottom: none;

      &:after {
        @include breakpoint($md) {
          border-color: transparent transparent transparent $wcm-red;
        }
      }
    }
  }
}
