.wcm-video-callout__wrapper .row {
  @include breakpoint($md) {
    display: flex;
    flex-wrap: wrap;
    &:before {
      display: block;
    }
  }
}

.wcm-video-callout__wrapper .row > [class*='col-'] {
  @include breakpoint($md) {
    display: flex;
    flex-direction: column;
  }
}

.wcm-video-callout__wrapper {
  background: $wcm-dark-gray;
  padding: 15px;
  @include breakpoint($md) {
    padding: 30px;
  }

  iframe {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    &.media-vimeo-player {
      position: absolute;
    }
  }

  .media-vimeo-video {
    padding:56.25% 0 0 0;
    position:relative;
  }

  a {
    color: $wcm-yellow;

    &:focus, &:hover {
      color: $wcm-yellow;
    }
  }

  .wcm-video-panel:first-child {
    padding-bottom: 10px;
    @include breakpoint($md) {
      padding-bottom: 0;
    }
  }

  .wcm-video-panel-content {
    @include breakpoint($md) {
      @include v-center();
      width: 100%;
    }
  }

  .wcm-video-panel-content-stacked {
    padding-top: 20px;
  }

  .wcm-video__title {
    font-family: $wcm-bold;
    font-size: 30px;
    line-height: 36px;
    color: $wcm-yellow;
    margin-bottom: 10px;
  }

  .wcm-video__description {
    color: $wcm-white;
  }
}

.wcm-video-callout {
  background-repeat: no-repeat;
  background-size: cover;

  @include breakpoint( (max: 991px) ) {
    background-image: none !important;
  }

  @include breakpoint($md) {
    padding: 40px 20px;
  }

  &--no-bg {
    @include breakpoint($md) {
      padding: 20px 0;
    }
  }

  &--no-text {
    padding: 0;

    .wcm-video-callout__wrapper {
      padding: 0;
      background: none;
    }

    a {
      color: $wcm-dark-orange;

      &:focus, &:hover {
        color: $wcm-red;
      }
    }
  }
}
