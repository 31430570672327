/**
 * Styles for the WCM Slide Navigation
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =WCM Paragraph Page Elements
----------------------------------------------------------*/
.pane-bundle-wcm-page-item .pane-title {
  color: #555555;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 1.5px;
  margin: 0 0 20px;
  text-transform: uppercase;
}

.pane-bundle-wcm-page-item .pane-title a {
  color: #555555;
}

.pane-bundle-wcm-page-item .pane-title a:hover {
  color: #b31b1b;
}

/* =Individual Card
----------------------------------------------------------*/
.wcm-pgraph-card {
  display: flex;
  margin-bottom: 20px;
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-card {
    margin-bottom: 0;
  }
}

.wcm-pgraph-card a {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: none;
}

.wcm-pgraph-card a:hover {
  border: none;
}

.wcm-pgraph-card__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.wcm-pgraph-card__image {
  overflow: hidden;
}

.wcm-pgraph-card__image img {
  width: 100%;
  height: auto;
  transition: all 0.3s ease 0s;
}

@media screen and (min-width: 992px) {
  a:hover .wcm-pgraph-card__image img {
    transform: scale(1.1);
  }
}

.wcm-pgraph-card__text {
  background: #f7f7f7;
  padding: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.wcm-pgraph-card__text__headline {
  margin-bottom: 10px;
  color: #b31b1b;
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 18px;
}

.wcm-pgraph-card__text__description {
  color: #555555;
}

@media screen and (min-width: 992px) {
  a:hover .wcm-pgraph-card__text:before {
    color: #b31b1b;
  }
}

@media screen and (min-width: 992px) {
  a:hover .wcm-pgraph-card__text__headline {
    text-decoration: underline;
  }
}

.wcm-pgraph-card--linked .wcm-pgraph-card__text:before {
  position: relative;
  top: -40px;
  margin: 0 auto -40px;
  content: '\e802';
  width: 50px;
  height: 50px;
  color: #e7751d;
  background: #f7f7f7;
  border-radius: 50%;
  font-size: 20px;
  text-align: center;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  line-height: 50px;
  display: block;
}

/* =Card Section Layout
----------------------------------------------------------*/
.wcm-pgraph-card-section {
  margin-left: -20px;
  margin-right: -20px;
}

.wcm-pgraph-card-section:before, .wcm-pgraph-card-section:after {
  content: " ";
  display: table;
}

.wcm-pgraph-card-section:after {
  clear: both;
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-card-section {
    display: flex;
    margin-bottom: 30px;
  }
}

.wcm-pgraph-card-section .wcm-pgraph-card {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
}

.wcm-pgraph-card-section .wcm-pgraph-card:nth-last-child(2):first-child,
.wcm-pgraph-card-section .wcm-pgraph-card:nth-last-child(2):first-child ~ .wcm-pgraph-card {
  position: relative;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 992px) {
  .wcm-pgraph-card-section .wcm-pgraph-card:nth-last-child(2):first-child,
  .wcm-pgraph-card-section .wcm-pgraph-card:nth-last-child(2):first-child ~ .wcm-pgraph-card {
    float: left;
    width: 50%;
  }
}

.wcm-pgraph-card-section .wcm-pgraph-card:nth-last-child(3):first-child,
.wcm-pgraph-card-section .wcm-pgraph-card:nth-last-child(3):first-child ~ .wcm-pgraph-card {
  position: relative;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 992px) {
  .wcm-pgraph-card-section .wcm-pgraph-card:nth-last-child(3):first-child,
  .wcm-pgraph-card-section .wcm-pgraph-card:nth-last-child(3):first-child ~ .wcm-pgraph-card {
    float: left;
    width: 33.33333%;
  }
}

.wcm-pgraph-card-section .wcm-pgraph-card:nth-last-child(4):first-child,
.wcm-pgraph-card-section .wcm-pgraph-card:nth-last-child(4):first-child ~ .wcm-pgraph-card {
  position: relative;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 992px) {
  .wcm-pgraph-card-section .wcm-pgraph-card:nth-last-child(4):first-child,
  .wcm-pgraph-card-section .wcm-pgraph-card:nth-last-child(4):first-child ~ .wcm-pgraph-card {
    float: left;
    width: 25%;
  }
}

.wcm-pgraph-img-teaser__image {
  overflow: hidden;
}

.wcm-pgraph-img-teaser__image img {
  width: 100%;
  transition: all 0.3s ease 0s;
}

.wcm-pgraph-img-teaser__text {
  background-color: #fff;
  position: relative;
  padding: 2rem 0;
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-img-teaser__text {
    width: 84%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -4rem;
    padding: 2rem 3rem;
    transform: translateY(-50%);
  }
}

.wcm-pgraph-img-teaser__text__headline {
  color: #b31b1b;
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 30px;
}

.wcm-pgraph-img-teaser__text__headline a {
  color: #b31b1b;
  text-decoration: underline;
  border: none;
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-img-teaser__text__headline a {
    text-decoration: none;
  }
}

.wcm-pgraph-img-teaser__text__headline a.external-link:after {
  content: '';
  display: none;
}

.wcm-pgraph-img-teaser__text__description {
  color: #555555;
}

.wcm-pgraph-img-teaser__text__indicator {
  border-bottom: none;
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-img-teaser__text__indicator {
    display: block;
    position: absolute;
    height: 50%;
    top: 25%;
    right: 5%;
    border-left: 1px solid #ddd;
    padding-left: 30px;
    z-index: 10;
  }
  .wcm-pgraph-img-teaser__text__indicator:after {
    content: "";
    position: relative;
    top: 33%;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent #e7751d;
  }
  .wcm-pgraph-img-teaser__text__indicator.external-link:after {
    border: none;
    font-size: 20px;
    width: 10px;
    padding-left: 0px !important;
  }
}

@media screen and (max-width: 991px) {
  .wcm-pgraph-img-teaser__text__indicator.external-link:after {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-img-teaser--linked .wcm-pgraph-img-teaser__text__description {
    padding-right: 90px;
  }
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-img-teaser--over .wcm-pgraph-img-teaser__image a img {
    transform: scale(1.1);
  }
}

.wcm-pgraph-img-teaser--over .wcm-pgraph-img-teaser__text__headline a {
  border: none;
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-img-teaser--over .wcm-pgraph-img-teaser__text__headline a {
    text-decoration: underline;
  }
}

.wcm-pgraph-img-teaser--over .wcm-pgraph-img-teaser__text__indicator {
  border-bottom: none;
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-img-teaser--over .wcm-pgraph-img-teaser__text__indicator:after {
    border-color: transparent transparent transparent #b31b1b;
  }
}

/* =Teaser Section Layout
----------------------------------------------------------*/
.wcm-pgraph-teaser-section {
  margin-left: -20px;
  margin-right: -20px;
}

.wcm-pgraph-teaser-section:before, .wcm-pgraph-teaser-section:after {
  content: " ";
  display: table;
}

.wcm-pgraph-teaser-section:after {
  clear: both;
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-teaser-section {
    display: flex;
    margin-bottom: 30px;
  }
}

.wcm-pgraph-teaser-section .wcm-pgraph-text-teaser-wrap {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
}

.wcm-pgraph-teaser-section .wcm-pgraph-text-teaser-wrap:nth-last-child(2):first-child,
.wcm-pgraph-teaser-section .wcm-pgraph-text-teaser-wrap:nth-last-child(2):first-child ~ .wcm-pgraph-text-teaser-wrap {
  position: relative;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 992px) {
  .wcm-pgraph-teaser-section .wcm-pgraph-text-teaser-wrap:nth-last-child(2):first-child,
  .wcm-pgraph-teaser-section .wcm-pgraph-text-teaser-wrap:nth-last-child(2):first-child ~ .wcm-pgraph-text-teaser-wrap {
    float: left;
    width: 50%;
  }
}

.wcm-pgraph-teaser-section .wcm-pgraph-text-teaser-wrap:nth-last-child(3):first-child,
.wcm-pgraph-teaser-section .wcm-pgraph-text-teaser-wrap:nth-last-child(3):first-child ~ .wcm-pgraph-text-teaser-wrap {
  position: relative;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 992px) {
  .wcm-pgraph-teaser-section .wcm-pgraph-text-teaser-wrap:nth-last-child(3):first-child,
  .wcm-pgraph-teaser-section .wcm-pgraph-text-teaser-wrap:nth-last-child(3):first-child ~ .wcm-pgraph-text-teaser-wrap {
    float: left;
    width: 33.33333%;
  }
}

.wcm-pgraph-teaser-section .wcm-pgraph-text-teaser-wrap:nth-last-child(4):first-child,
.wcm-pgraph-teaser-section .wcm-pgraph-text-teaser-wrap:nth-last-child(4):first-child ~ .wcm-pgraph-text-teaser-wrap {
  position: relative;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 992px) {
  .wcm-pgraph-teaser-section .wcm-pgraph-text-teaser-wrap:nth-last-child(4):first-child,
  .wcm-pgraph-teaser-section .wcm-pgraph-text-teaser-wrap:nth-last-child(4):first-child ~ .wcm-pgraph-text-teaser-wrap {
    float: left;
    width: 25%;
  }
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-teaser-section .wcm-pgraph-text-teaser {
    margin-bottom: 0;
    height: 100%;
  }
}

.wcm-pgraph-text-teaser--centered {
  text-align: center;
}

.wcm-pgraph-text-teaser--inset {
  background: white;
  margin-bottom: 30px;
}

.wcm-pgraph-text-teaser--inset > a {
  display: block;
  border: none;
}

.wcm-pgraph-text-teaser--inset > a:hover {
  text-decoration: none;
  border: none;
}

.wcm-pgraph-text-teaser--inset > a:after {
  display: none !important;
  content: '' !important;
}

.wcm-pgraph-text-teaser--inset .wcm-pgraph-text-teaser__text {
  border: 30px solid #f7f7f7;
  padding: 40px;
}

.wcm-pgraph-text-teaser--inset .wcm-pgraph-text-teaser__text__headline {
  color: #b31b1b;
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 20px;
}

@media screen and (min-width: 768px) {
  .wcm-pgraph-text-teaser--inset .wcm-pgraph-text-teaser__text__headline {
    font-size: 24px;
  }
}

.wcm-pgraph-text-teaser--inset .wcm-pgraph-text-teaser__text__headline a {
  color: #b31b1b;
  text-decoration: underline;
  border: none;
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-text-teaser--inset .wcm-pgraph-text-teaser__text__headline a {
    text-decoration: none;
  }
}

.wcm-pgraph-text-teaser--inset .wcm-pgraph-text-teaser__text__headline a:after {
  content: '\e80d';
  color: #e7751d;
  padding-left: 10px;
  font-size: 60%;
  vertical-align: middle;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  text-decoration: none;
  line-height: 1.5;
}

.wcm-pgraph-text-teaser--inset .wcm-pgraph-text-teaser__text__headline a:hover {
  border: none;
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-text-teaser--inset .wcm-pgraph-text-teaser__text__headline a:hover {
    text-decoration: underline;
  }
}

.wcm-pgraph-text-teaser--inset .wcm-pgraph-text-teaser__text__description {
  color: #555555;
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-text-teaser--inset.wcm-pgraph-text-teaser--over .wcm-pgraph-text-teaser__text {
    border-color: #f2f2f2;
  }
}

.wcm-pgraph-text-teaser--centered {
  text-align: center;
}

.wcm-pgraph-text-teaser--gray {
  background: #f7f7f7;
  margin-bottom: 30px;
}

.wcm-pgraph-text-teaser--gray .wcm-pgraph-text-teaser__text {
  padding: 40px;
}

.wcm-pgraph-text-teaser--gray .wcm-pgraph-text-teaser__text__headline {
  color: #b31b1b;
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 20px;
}

@media screen and (min-width: 768px) {
  .wcm-pgraph-text-teaser--gray .wcm-pgraph-text-teaser__text__headline {
    font-size: 24px;
  }
}

.wcm-pgraph-text-teaser--gray .wcm-pgraph-text-teaser__text__headline a {
  color: #b31b1b;
  border: none;
  text-decoration: underline;
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-text-teaser--gray .wcm-pgraph-text-teaser__text__headline a {
    text-decoration: none;
  }
}

.wcm-pgraph-text-teaser--gray .wcm-pgraph-text-teaser__text__headline a:after {
  content: '\e80d';
  color: #e7751d;
  padding-left: 10px;
  font-size: 60%;
  vertical-align: middle;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  text-decoration: none;
  line-height: 1.5;
}

.wcm-pgraph-text-teaser--gray .wcm-pgraph-text-teaser__text__headline a:hover {
  border: none;
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-text-teaser--gray .wcm-pgraph-text-teaser__text__headline a:hover {
    text-decoration: underline;
  }
}

.wcm-pgraph-text-teaser--gray .wcm-pgraph-text-teaser__text__description {
  color: #555555;
}

.wcm-pgraph-text-teaser--gray.wcm-pgraph-text-teaser--linked {
  border: solid #ddd;
  border-width: 0 10px 0 0;
  position: relative;
}

.wcm-pgraph-text-teaser--gray.wcm-pgraph-text-teaser--linked:before {
  content: "";
  display: block;
  position: absolute;
  right: -1.8rem;
  top: 45%;
  width: 0;
  height: 0;
  border: 1rem solid transparent;
  border-left-color: #f7f7f7;
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-text-teaser--gray.wcm-pgraph-text-teaser--linked.wcm-pgraph-text-teaser--over {
    text-decoration: none;
    border: solid #b31b1b;
    border-width: 0 10px 0 0;
  }
}

.wcm-pgraph-text-teaser--centered {
  text-align: center;
}

.wcm-pgraph-text-teaser--standard {
  background: white;
  margin-bottom: 30px;
}

.wcm-pgraph-text-teaser--standard .wcm-pgraph-text-teaser__text__headline a {
  display: block;
  color: #b31b1b;
  border: none;
  text-decoration: underline;
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-text-teaser--standard .wcm-pgraph-text-teaser__text__headline a {
    text-decoration: none;
  }
}

.wcm-pgraph-text-teaser--standard .wcm-pgraph-text-teaser__text__headline a:after {
  content: '\e80d';
  color: #e7751d;
  padding-left: 10px;
  font-size: 60%;
  vertical-align: middle;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  text-decoration: none;
  line-height: 1.5;
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-text-teaser--standard .wcm-pgraph-text-teaser__text__headline a:hover {
    text-decoration: underline;
  }
}

.wcm-pgraph-text-teaser--standard .wcm-pgraph-text-teaser__text {
  padding: 40px;
}

.wcm-pgraph-text-teaser--standard .wcm-pgraph-text-teaser__text__headline {
  color: #b31b1b;
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 20px;
}

@media screen and (min-width: 768px) {
  .wcm-pgraph-text-teaser--standard .wcm-pgraph-text-teaser__text__headline {
    font-size: 24px;
  }
}

.wcm-pgraph-text-teaser--standard .wcm-pgraph-text-teaser__text__description {
  color: #555555;
}

.widget-preview .wcm-pgraph-side-callout .wcm-pgraph-side-callout__text__headline {
  font-size: 9.5px;
}

.widget-preview .wcm-pgraph-side-callout .wcm-pgraph-side-callout__text__description {
  font-size: 10px;
}

.wcm-pgraph-side-callout {
  margin-left: 0px;
  margin-right: 0px;
}

.wcm-pgraph-side-callout:before, .wcm-pgraph-side-callout:after {
  content: " ";
  display: table;
}

.wcm-pgraph-side-callout:after {
  clear: both;
}

@media screen and (min-width: 768px) {
  .wcm-pgraph-side-callout {
    display: flex;
  }
}

.wcm-pgraph-side-callout > a {
  border: none;
}

@media screen and (min-width: 768px) {
  .wcm-pgraph-side-callout > a {
    display: flex;
    width: 100%;
  }
}

.wcm-pgraph-side-callout > a:hover {
  border: none;
}

.wcm-pgraph-side-callout__text {
  margin-bottom: 20px;
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  min-height: 1px;
  padding-left: 0px;
  padding-right: 0px;
}

@media (min-width: 768px) {
  .wcm-pgraph-side-callout__text {
    float: left;
    width: 50%;
  }
}

@media screen and (min-width: 768px) {
  .wcm-pgraph-side-callout__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #f7f7f7;
    text-align: center;
    margin: 0;
    padding: 0 20px;
  }
}

.wcm-pgraph-side-callout__text__headline {
  color: #b31b1b;
  margin: 0 0 5px;
  font-size: 15px;
  font-family: "1898Sans-Bold", sans-serif;
}

@media screen and (min-width: 768px) {
  .wcm-pgraph-side-callout__text__headline {
    font-size: 14px;
  }
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-side-callout__text__headline {
    font-size: 16px;
  }
}

@media screen and (min-width: 1200px) {
  .wcm-pgraph-side-callout__text__headline {
    font-size: 18px;
  }
}

.wcm-pgraph-side-callout__text__description {
  color: #666666;
  line-height: 1.6;
  font-size: 15px;
}

a .wcm-pgraph-side-callout__text__headline {
  text-decoration: underline;
}

@media screen and (min-width: 992px) {
  a .wcm-pgraph-side-callout__text__headline {
    text-decoration: none;
  }
}

a .wcm-pgraph-side-callout__text__headline:after {
  content: '\e80d';
  color: #e7751d;
  padding-left: 10px;
  font-size: 60%;
  vertical-align: middle;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  text-decoration: none;
  line-height: 1.5;
}

@media screen and (min-width: 992px) {
  a:hover .wcm-pgraph-side-callout__text__headline {
    text-decoration: underline;
  }
}

.wcm-pgraph-side-callout__image {
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  min-height: 1px;
  padding-left: 0px;
  padding-right: 0px;
}

@media (min-width: 768px) {
  .wcm-pgraph-side-callout__image {
    float: left;
    width: 50%;
  }
}

@media screen and (min-width: 768px) {
  .wcm-pgraph-side-callout__image {
    margin-bottom: 0;
  }
}

.wcm-pgraph-side-callout__image img {
  width: 100%;
  transition: all 0.3s ease 0s;
}

@media screen and (min-width: 992px) {
  a:hover .wcm-pgraph-side-callout__image img {
    transform: scale(1.1);
  }
}

@media screen and (min-width: 768px) {
  .wcm-pgraph-side-callout--image-right .wcm-pgraph-side-callout__text {
    right: 50%;
  }
  .wcm-pgraph-side-callout--image-right .wcm-pgraph-side-callout__image {
    left: 50%;
  }
}

@media screen and (min-width: 768px) {
  .wcm-pgraph-side-callout--dark-orange .wcm-pgraph-side-callout__text {
    background: #cf4520;
  }
  .wcm-pgraph-side-callout--dark-orange .wcm-pgraph-side-callout__text .wcm-pgraph-side-callout__text__headline,
  .wcm-pgraph-side-callout--dark-orange .wcm-pgraph-side-callout__text .wcm-pgraph-side-callout__text__description {
    color: #fff;
  }
  .wcm-pgraph-side-callout--dark-orange .wcm-pgraph-side-callout__text .wcm-pgraph-side-callout__text__headline::after,
  .wcm-pgraph-side-callout--dark-orange .wcm-pgraph-side-callout__text .wcm-pgraph-side-callout__text__description::after {
    color: #fff;
  }
}

@media screen and (min-width: 768px) {
  .wcm-pgraph-side-callout--bright-orange .wcm-pgraph-side-callout__text {
    background: #e7751d;
  }
  .wcm-pgraph-side-callout--bright-orange .wcm-pgraph-side-callout__text .wcm-pgraph-side-callout__text__headline,
  .wcm-pgraph-side-callout--bright-orange .wcm-pgraph-side-callout__text .wcm-pgraph-side-callout__text__description {
    color: #fff;
  }
  .wcm-pgraph-side-callout--bright-orange .wcm-pgraph-side-callout__text .wcm-pgraph-side-callout__text__headline::after,
  .wcm-pgraph-side-callout--bright-orange .wcm-pgraph-side-callout__text .wcm-pgraph-side-callout__text__description::after {
    color: #fff;
  }
}

@media screen and (min-width: 768px) {
  .wcm-pgraph-side-callout--red .wcm-pgraph-side-callout__text {
    background: #b31b1b;
  }
  .wcm-pgraph-side-callout--red .wcm-pgraph-side-callout__text .wcm-pgraph-side-callout__text__headline,
  .wcm-pgraph-side-callout--red .wcm-pgraph-side-callout__text .wcm-pgraph-side-callout__text__description {
    color: #fff;
  }
  .wcm-pgraph-side-callout--red .wcm-pgraph-side-callout__text .wcm-pgraph-side-callout__text__headline::after,
  .wcm-pgraph-side-callout--red .wcm-pgraph-side-callout__text .wcm-pgraph-side-callout__text__description::after {
    color: #fff;
  }
}

@media screen and (min-width: 768px) {
  .wcm-pgraph-side-callout--headline-large .wcm-pgraph-side-callout__text__headline {
    font-size: 16px;
  }
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-side-callout--headline-large .wcm-pgraph-side-callout__text__headline {
    font-size: 22px;
  }
}

@media screen and (min-width: 1200px) {
  .wcm-pgraph-side-callout--headline-large .wcm-pgraph-side-callout__text__headline {
    font-size: 24px;
  }
}

@media screen and (min-width: 768px) {
  .wcm-pgraph-side-callout--last {
    margin-bottom: 30px;
  }
}

.wcm-social-icons {
  margin-bottom: 40px;
}

.wcm-social-icons__title {
  text-align: center;
  margin: 0 0 14px;
}

.wcm-social-icons__grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.wcm-social-icons__icon {
  background-color: #cf4520;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  border: 0;
  width: 48px;
  height: 48px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wcm-social-icons__icon:hover {
  color: #ffc72c;
  text-decoration: none;
  border: 0;
}

.wcm-social-icons__icon::before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  font-size: 30px;
}

.wcm-social-icons__icon::after {
  content: none !important;
}

.wcm-social-icons__icon-twitter::before {
  content: '\e815';
}

.wcm-social-icons__icon-facebook::before {
  content: '\f308';
}

.wcm-social-icons__icon-linkedin::before {
  content: '\e806';
}

.wcm-social-icons__icon-instagram::before {
  content: '\f16d';
}

.wcm-social-icons__icon-youtube::before {
  content: '\f16a';
}

.wcm-social-icons__icon-vimeo::before {
  content: '\f27d';
}

.wcm-pgraph-hero-callout {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-size: cover;
  background-position: center;
  color: #fff;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .wcm-pgraph-hero-callout {
    min-height: 450px;
  }
}

@media screen and (max-width: 767px) {
  .wcm-pgraph-hero-callout {
    margin: 0 0 195px;
  }
}

.wcm-pgraph-hero-callout__container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 767px) {
  .wcm-pgraph-hero-callout__container {
    padding: 0;
  }
}

@media screen and (min-width: 768px) {
  .wcm-pgraph-hero-callout__container {
    width: 760px;
  }
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-hero-callout__container {
    width: 980px;
  }
}

@media screen and (min-width: 1200px) {
  .wcm-pgraph-hero-callout__container {
    width: 1180px;
  }
}

.wcm-pgraph-hero-callout__bg-blend {
  position: relative;
  padding: 10px 30px;
  float: right;
}

@media screen and (max-width: 767px) {
  .wcm-pgraph-hero-callout__bg-blend {
    bottom: -150px;
    margin: 0 25px;
  }
}

@media screen and (min-width: 768px) {
  .wcm-pgraph-hero-callout__bg-blend {
    max-width: 55%;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-hero-callout__bg-blend {
    max-width: 40%;
    margin-bottom: 20px;
  }
}

.wcm-pgraph-hero-callout__bg-blend::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
  background-color: #cf4520;
}

@media screen and (min-width: 768px) {
  .wcm-pgraph-hero-callout__bg-blend::before {
    mix-blend-mode: multiply;
  }
}

.wcm-pgraph-hero-callout__bg-blend__inner {
  position: relative;
  z-index: 2;
}

.wcm-pgraph-hero-callout__bg-blend__inner h2 {
  color: #fff;
  margin-top: 25px;
  font-family: "1898Sans-Regular", sans-serif;
  font-weight: 400;
}

@media screen and (max-width: 767px) {
  .wcm-pgraph-hero-callout__bg-blend__inner h2 {
    font-family: "1898Sans-Regular", sans-serif;
  }
}

.wcm-pgraph-hero-callout__bg-blend__inner hr {
  border-top: 1px solid #e7751d;
  width: 20%;
  margin-left: 0;
  margin-bottom: 20px;
}

.wcm-pgraph-hero-callout__bg-blend__inner .field-wcm-pgraph-hero-desc {
  margin-bottom: 20px;
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-hero-callout__bg-blend__inner .field-wcm-pgraph-hero-desc {
    font-size: 15px;
  }
}

.wcm-pgraph-hero-callout__bg-blend__inner .field-wcm-pgraph-hero-cta a {
  color: #fff;
  border-color: #fff;
}

.wcm-pgraph-hero-callout--text-pos-left .wcm-pgraph-hero-callout__bg-blend {
  float: left;
}

@media screen and (min-width: 992px) {
  .wcm-video-callout__wrapper .row {
    display: flex;
    flex-wrap: wrap;
  }
  .wcm-video-callout__wrapper .row:before {
    display: block;
  }
}

@media screen and (min-width: 992px) {
  .wcm-video-callout__wrapper .row > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }
}

.wcm-video-callout__wrapper {
  background: #555555;
  padding: 15px;
}

@media screen and (min-width: 992px) {
  .wcm-video-callout__wrapper {
    padding: 30px;
  }
}

.wcm-video-callout__wrapper iframe {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.wcm-video-callout__wrapper iframe.media-vimeo-player {
  position: absolute;
}

.wcm-video-callout__wrapper .media-vimeo-video {
  padding: 56.25% 0 0 0;
  position: relative;
}

.wcm-video-callout__wrapper a {
  color: #ffc72c;
}

.wcm-video-callout__wrapper a:focus, .wcm-video-callout__wrapper a:hover {
  color: #ffc72c;
}

.wcm-video-callout__wrapper .wcm-video-panel:first-child {
  padding-bottom: 10px;
}

@media screen and (min-width: 992px) {
  .wcm-video-callout__wrapper .wcm-video-panel:first-child {
    padding-bottom: 0;
  }
}

@media screen and (min-width: 992px) {
  .wcm-video-callout__wrapper .wcm-video-panel-content {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
    -ms-transform: translateY(-50%);
    /* IE 9 */
    transform: translateY(-50%);
    /* IE 10, Fx 16+, Op 12.1+ */
    width: 100%;
  }
}

.wcm-video-callout__wrapper .wcm-video-panel-content-stacked {
  padding-top: 20px;
}

.wcm-video-callout__wrapper .wcm-video__title {
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 30px;
  line-height: 36px;
  color: #ffc72c;
  margin-bottom: 10px;
}

.wcm-video-callout__wrapper .wcm-video__description {
  color: #fff;
}

.wcm-video-callout {
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 991px) {
  .wcm-video-callout {
    background-image: none !important;
  }
}

@media screen and (min-width: 992px) {
  .wcm-video-callout {
    padding: 40px 20px;
  }
}

@media screen and (min-width: 992px) {
  .wcm-video-callout--no-bg {
    padding: 20px 0;
  }
}

.wcm-video-callout--no-text {
  padding: 0;
}

.wcm-video-callout--no-text .wcm-video-callout__wrapper {
  padding: 0;
  background: none;
}

.wcm-video-callout--no-text a {
  color: #cf4520;
}

.wcm-video-callout--no-text a:focus, .wcm-video-callout--no-text a:hover {
  color: #b31b1b;
}

.wcm-pgraph-video-playlist-wrapper {
  background: #555555;
  padding: 15px;
}

@media screen and (min-width: 992px) {
  .wcm-pgraph-video-playlist-wrapper {
    padding: 30px;
  }
}

.wcm-pgraph-video-playlist-wrapper .wcm-video-playlist__player:before, .wcm-pgraph-video-playlist-wrapper .wcm-video-playlist__player:after {
  content: " ";
  display: table;
}

.wcm-pgraph-video-playlist-wrapper .wcm-video-playlist__player:after {
  clear: both;
}

.wcm-pgraph-video-playlist-wrapper .wcm-video-playlist__title {
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 30px;
  line-height: 36px;
  color: #ffc72c;
  margin-bottom: 10px;
}

.wcm-pgraph-video-playlist-wrapper .wcm-video-playlist__description {
  color: #fff;
}

.wcm-pgraph-video-playlist-wrapper .fancybox-transcript-link {
  background-color: transparent;
}

.wcm-pgraph-video-playlist-wrapper .fancybox-transcript-link a {
  color: #ffc72c;
}

@media screen and (max-width: 767px) {
  .wcm-pgraph-video-playlist-wrapper {
    display: flex;
    flex-direction: column;
  }
  .wcm-pgraph-video-playlist-wrapper .wcm-video-playlist__title {
    order: 1;
  }
  .wcm-pgraph-video-playlist-wrapper .wcm-video-playlist__description {
    order: 2;
  }
  .wcm-pgraph-video-playlist-wrapper .wcm-video-playlist__player {
    order: 3;
    padding-bottom: 50px;
  }
}

/* =Misc Utilities
----------------------------------------------------------*/
.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}
