.wcm-pgraph-hero-callout {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-size: cover;
  background-position: center;
  color: $wcm-white;
  margin-bottom: 20px;

  @include breakpoint($sm) {
    min-height: 450px;
  }

  @include breakpoint($xs) {
    margin: 0 0 195px;
  }

  &__container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;

    @include breakpoint($xs) {
      padding: 0;
    }
    @include breakpoint($sm) {
      width: 760px;
    }
    @include breakpoint($md) {
      width: 980px;
    }
    @include breakpoint($lg) {
      width: 1180px;
    }
  }

  &__bg-blend {
    position: relative;
    padding: 10px 30px;
    float: right;

    @include breakpoint($xs) {
      bottom: -150px;
      margin: 0 25px;
    }
    @include breakpoint($sm) {
      max-width: 55%;
      margin-bottom: 20px;
    }
    @include breakpoint($md) {
      max-width: 40%;
      margin-bottom: 20px;
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      z-index: 1;
      background-color: $wcm-dark-orange;
      @include breakpoint($sm) {
        mix-blend-mode: multiply;
      }
    }

    &__inner {
      position: relative;
      z-index: 2;

      h2 {
        color: $wcm-white;
        margin-top: 25px;
        font-family: $wcm-regular;
        font-weight: 400;

        @include breakpoint($xs) {
          font-family: $wcm-regular;
        }
      }
      hr {
        border-top: 1px solid $wcm-bright-orange;
        width: 20%;
        margin-left: 0;
        margin-bottom: 20px;
      }
      .field-wcm-pgraph-hero-desc {
        margin-bottom: 20px;
        @include breakpoint($md) {
          font-size: 15px;
        }
      }
      .field-wcm-pgraph-hero-cta {
        a {
          color: $wcm-white;
          border-color: $wcm-white;
        }
      }
    }
  }
  &--text-pos-left {
    .wcm-pgraph-hero-callout__bg-blend {
      float: left;
    }
  }
}
