.wcm-pgraph-text-teaser {
  $this: &;
  &--centered {
    text-align: center;
  }
  // Gray Background Variation
  &--gray {
    background: #f7f7f7;
    margin-bottom: 30px;
    #{$this}__text {
      padding: 40px;
      &__headline {
        color: $wcm-red;
        font-family: $wcm-bold;
        font-size: 20px;
        @include breakpoint($sm) {
          font-size: 24px;
        }
        a {
          color: $wcm-red;
          border: none;
          text-decoration: underline;
          @include breakpoint($md) {
            text-decoration: none;
          }
          &:after {
            content: '\e80d';
            color: $wcm-bright-orange;
            padding-left: 10px;
            font-size: 60%;
            vertical-align: middle;
            @include fontello();
            text-decoration: none;
            line-height: 1.5;
          }
          &:hover {
            border: none;
            @include breakpoint($md) {
              text-decoration: underline;
            }
          }
        }
      }
      &__description {
        color: $wcm-dark-gray;
      }
    }
  }
  &--gray#{$this}--linked {
    border: solid #ddd;
    border-width: 0 10px 0 0;
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      right: -1.8rem;
      top: 45%;
      width: 0;
      height: 0;
      border: 1rem solid transparent;
      border-left-color: #f7f7f7;
    }
  }
  &--gray#{$this}--linked#{$this}--over {
    @include breakpoint($md) {
      text-decoration: none;
      border: solid $wcm-red;
      border-width: 0 10px 0 0;
    }
  }
}
