/**
 * Styles for the WCM Slide Navigation
 *
 */

// Bring in a few things from Bootstrap so we can use the grid, clearfix and vendor mixins (for transition, etc)
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/variables";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_clearfix";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_grid";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_vendor-prefixes";

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../../../themes/wcm_brand_base/scss/_variables";
@import "../../../../themes/wcm_brand_base/scss/_mixins";

/* =WCM Paragraph Page Elements
----------------------------------------------------------*/

// Section Header
.pane-bundle-wcm-page-item {
  .pane-title {
    color: $wcm-dark-gray;
    font-size: 18px;
    font-family: $font-family-sans-serif;
    font-weight: 700;
    letter-spacing: 1.5px;
    margin: 0 0 20px;
    text-transform: uppercase;

    a {
      color: $wcm-dark-gray;

      &:hover {
        color: $wcm-red;
      }
    }
  }
}

// Paragraphs
@import "card";
@import "img-teaser";
@import "text-teaser/text-teaser-layout";
@import "text-teaser/text-teaser--inset";
@import "text-teaser/text-teaser--gray";
@import "text-teaser/text-teaser--standard";
@import "side-callout";
@import "social-icons";
@import "hero-callout";
@import "video";
@import "video_playlist";

/* =Misc Utilities
----------------------------------------------------------*/

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters>[class^="col-"],
.row.no-gutters>[class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}
