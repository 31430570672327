.wcm-pgraph-video-playlist-wrapper {
  background: $wcm-dark-gray;
  padding: 15px;

  @include breakpoint($md) {
    padding: 30px;
  }

  .wcm-video-playlist__player {
    @include clearfix();
  }

  .wcm-video-playlist__title {
    font-family: $wcm-bold;
    font-size: 30px;
    line-height: 36px;
    color: $wcm-yellow;
    margin-bottom: 10px;
  }

  .wcm-video-playlist__description {
    color: $wcm-white;
  }

  .fancybox-transcript-link {
    background-color: transparent;

    a {
      color: $wcm-yellow;
    }
  }

  @include breakpoint($xs) {
    display: flex;
    flex-direction: column;

    .wcm-video-playlist__title {
      order: 1;
    }

    .wcm-video-playlist__description {
      order: 2;
    }

    .wcm-video-playlist__player {
      order: 3;
      padding-bottom: 50px;
    }
  }
}
