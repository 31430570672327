.wcm-social-icons {
  $this: &;
  margin-bottom: 40px;

  &__title {
    text-align: center;
    margin: 0 0 14px;
  }
  
  &__grid {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__icon {
    background-color: $wcm-dark-orange;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
    border: 0;
    width: 48px;
    height: 48px;
    color: $wcm-white;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: $wcm-yellow;
      text-decoration: none;
      border: 0;
    }
    &::before {
      @include fontello();
      font-size: 30px;
    }
    &::after {
      content: none !important;
    }
  }

  &__icon-twitter {
    &::before {
      content:'\e815';
    }
  }
  &__icon-facebook {
    &::before {
      content:'\f308';
    }
  }
  &__icon-linkedin {
    &::before {
      content:'\e806';
    }
  }
  &__icon-instagram {
    &::before {
      content:'\f16d';
    }
  }
  &__icon-youtube {
    &::before {
      content:'\f16a';
    }
  }
  &__icon-vimeo {
    &::before {
      content:'\f27d';
    }
  }
}
