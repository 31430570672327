/* =Individual Card
----------------------------------------------------------*/
.wcm-pgraph-card {
  display: flex;
  margin-bottom: 20px;
  @include breakpoint($md) {
    margin-bottom: 0;
  }
  $this: &;
  a {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border: none;
    &:hover {
      border: none;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  &__image {
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
      transition: all 0.3s ease 0s;
    }
  }
  a:hover &__image img {
    @include breakpoint($md) {
      transform: scale(1.1);
    }
  }
  &__text {
    background: $wcm-bg-gray;
    padding: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    &__headline {
      margin-bottom: 10px;
      color: $wcm-red;
      font-family: $wcm-bold;
      font-size: 18px;
    }
    &__description {
      color: $wcm-dark-gray;
    }
  }
  a:hover &__text:before {
    @include breakpoint($md) {
      color: $wcm-red;
    }
  }
  a:hover &__text__headline {
    @include breakpoint($md) {
      text-decoration: underline;
    }
  }
  &--linked {
    #{$this}__text {
      &:before {
        position: relative;
        top: -40px;
        margin: 0 auto -40px;
        content: '\e802';
        width: 50px;
        height: 50px;
        color: $wcm-bright-orange;
        background: $wcm-bg-gray;
        border-radius: 50%;
        font-size: 20px;
        text-align: center;
        @include fontello();
        line-height: 50px;
        display: block;
      }
    }
  }
}

/* =Card Section Layout
----------------------------------------------------------*/

.wcm-pgraph-card-section {
  @include make-row();
  @include breakpoint($md) {
    display: flex;
    margin-bottom: 30px;
  }
  .wcm-pgraph-card {
    // Make all 12 columns at mobile
    @include make-xs-column(12);

    &:nth-last-child(2):first-child, 
    &:nth-last-child(2):first-child ~ .wcm-pgraph-card {
      @include make-md-column(6);
    }

    &:nth-last-child(3):first-child, 
    &:nth-last-child(3):first-child ~ .wcm-pgraph-card {
      @include make-md-column(4);
    }

    &:nth-last-child(4):first-child, 
    &:nth-last-child(4):first-child ~ .wcm-pgraph-card {
      @include make-md-column(3);
    }
  }
}


