// Preview Styles
.widget-preview {
  .wcm-pgraph-side-callout {
    .wcm-pgraph-side-callout__text__headline {
      font-size: 9.5px;
    }
    .wcm-pgraph-side-callout__text__description {
      font-size: 10px;
    }
  }
}

.wcm-pgraph-side-callout {
  @include make-row(0px);
  @include breakpoint($sm) {
    display: flex;
  }
  > a {
    border: none;
    @include breakpoint($sm) {
      display: flex;
      width: 100%;
    }
    &:hover {
      border: none;
    }
  }
  &__text {
    margin-bottom: 20px;
    @include make-xs-column(12);
    @include make-sm-column(6, 0px);
    @include breakpoint($sm) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: $wcm-bg-gray; // Default to Gray background color
      text-align: center;
      margin: 0;
      padding: 0 20px;
    }
    &__headline {
      color: $wcm-red;
      margin: 0 0 5px;
      font-size: 15px;
      font-family: $wcm-bold;
      @include breakpoint($sm) {
        font-size: 14px;
      }
      @include breakpoint($md) {
        font-size: 16px;
      }
      @include breakpoint($lg) {
        font-size: 18px;
      }
    }
    &__description {
      color: $wcm-med-gray;
      line-height: 1.6;
      font-size: 15px;
    }
  }
  a &__text__headline {
    text-decoration: underline;
    @include breakpoint($md) {
      text-decoration: none;
    }
    &:after {
      content: '\e80d';
      color: $wcm-bright-orange;
      padding-left: 10px;
      font-size: 60%;
      vertical-align: middle;
      @include fontello();
      text-decoration: none;
      line-height: 1.5;
    }
  }
  a:hover &__text__headline {
    @include breakpoint($md) {
      text-decoration: underline;
    }
  }
  &__image {
    overflow: hidden;
    margin-bottom: 20px;
    @include make-xs-column(12);
    @include make-sm-column(6, 0px);
    @include breakpoint($sm) {
      margin-bottom: 0;
    }
    img {
      width: 100%;
      transition: all 0.3s ease 0s;
    }
  }
  a:hover &__image img {
    @include breakpoint($md) {
      transform: scale(1.1);
    }
  }
  &--image-right {
    @include breakpoint($sm) {
      // Override the default styles when "Image Right" option is selected
      .wcm-pgraph-side-callout__text {
        right: 50%;
      }
      .wcm-pgraph-side-callout__image {
        left: 50%;
      }
    }
  }
  &--dark-orange {
    // Override the default styles when different background colors are selected
    .wcm-pgraph-side-callout__text {
      @include breakpoint($sm) {
        background: $wcm-dark-orange;
        .wcm-pgraph-side-callout__text__headline,
        .wcm-pgraph-side-callout__text__description {
          color: $wcm-white;
          &::after {
            color: $wcm-white;
          }
        }
      }
    }
  }
  &--bright-orange {
    // Override the default styles when different background colors are selected
    .wcm-pgraph-side-callout__text {
      @include breakpoint($sm) {
        background: $wcm-bright-orange;
        .wcm-pgraph-side-callout__text__headline,
        .wcm-pgraph-side-callout__text__description {
          color: $wcm-white;
          &::after {
            color: $wcm-white;
          }
        }
      }
    }
  }
  &--red {
    // Override the default styles when different background colors are selected
    .wcm-pgraph-side-callout__text {
      @include breakpoint($sm) {
        background: $wcm-red;
        .wcm-pgraph-side-callout__text__headline,
        .wcm-pgraph-side-callout__text__description {
          color: $wcm-white;
          &::after {
            color: $wcm-white;
          }
        }
      }
    }
  }
  &--headline-large {
    // Override the default headline size when there is no description
    .wcm-pgraph-side-callout__text__headline {
      // font-size: 24px;
      @include breakpoint($sm) {
        font-size: 16px;
      }
      @include breakpoint($md) {
        font-size: 22px;
      }
      @include breakpoint($lg) {
        font-size: 24px;
      }
    }
  }
  &--last {
    @include breakpoint($sm) {
      margin-bottom: 30px;
    }
  }
}


