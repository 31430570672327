.wcm-pgraph-text-teaser {
  $this: &;
  &--centered {
    text-align: center;
  }
  // Standard Variation
  &--standard {
    background: white;
    margin-bottom: 30px;
    #{$this}__text__headline a {
      display: block;
      color: $wcm-red;
      border: none;
      text-decoration: underline;
      @include breakpoint($md) {
        text-decoration: none;
      }
      &:after {
        content: '\e80d';
        color: $wcm-bright-orange;
        padding-left: 10px;
        font-size: 60%;
        vertical-align: middle;
        @include fontello();
        text-decoration: none;
        line-height: 1.5;
      }
      &:hover {
        @include breakpoint($md) {
          text-decoration: underline;
        }
      }
    }
    #{$this}__text {
      padding: 40px;
      &__headline {
        color: $wcm-red;
        font-family: $wcm-bold;
        font-size: 20px;
        @include breakpoint($sm) {
          font-size: 24px;
        }
      }
      &__description {
        color: $wcm-dark-gray;
      }
    }
  }
}
